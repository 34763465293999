var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-container"},[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"upload-list-component",style:({ width: _vm.width, height: _vm.height }),on:{"mouseenter":function($event){_vm.showOptionIndex = index},"mouseleave":function($event){_vm.showOptionIndex = -1}}},[(
        _vm.videoType.indexOf(item.substring(item.lastIndexOf('.') + 1)) == -1
      )?_c('img',{staticClass:"upload-list-component-image",attrs:{"src":item}}):_c('video',{staticClass:"upload-list-component-image",attrs:{"src":item}}),(index == _vm.showOptionIndex)?_c('div',{staticClass:"upload-list-component-option"},[(!_vm.preview)?_c('i',{staticClass:"option-icon el-icon-back",on:{"click":function($event){return _vm.handleLeft(index)}}}):_vm._e(),(!_vm.preview)?_c('i',{staticClass:"option-icon el-icon-delete",on:{"click":function($event){return _vm.handleRemove(index)}}}):_vm._e(),_c('i',{staticClass:"option-icon el-icon-zoom-in",on:{"click":function($event){return _vm.handleWatch(index)}}}),(!_vm.preview)?_c('i',{staticClass:"option-icon el-icon-right",on:{"click":function($event){return _vm.handleRight(index)}}}):_vm._e()]):_vm._e()])}),_c('el-upload',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"upload-list-component",style:({ width: _vm.width, height: _vm.height }),attrs:{"action":_vm.qiniu.actionPath,"data":_vm.qiniu.postData,"show-file-list":false,"before-upload":_vm.beforeUpload,"on-success":_vm.uploadSuccess}},[_c('i',{staticClass:"el-icon-plus upload-list-component-icon"})]),_c('el-dialog',{attrs:{"visible":_vm.showPreview,"append-to-body":""},on:{"update:visible":function($event){_vm.showPreview=$event}}},[(
        _vm.videoType.indexOf(
          _vm.previews.substring(_vm.previews.lastIndexOf('.') + 1)
        ) == -1
      )?_c('img',{attrs:{"src":_vm.previews,"width":"400px"}}):_c('video',{ref:"videoPreview",attrs:{"src":_vm.previews,"width":"400px","autoplay":"true","controls":"true"}})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }